import React, { useEffect, useState } from 'react';
import './styles/styles.css';
import { connectionData, endpoint } from '../../connection/connection';
import TableSelect from '../../components/TableSelectNumber';

const ModalCalendar = () => {
  const [contacts, setContacts] = useState();
  const [ structure , setStructure ] = useState();
  const [messages, setMessages] = useState();
    useEffect( async () => {
        setContacts([{id:'1',name:'name1',number:'32423423'},{id:'1',name:'name1',number:'32423423'},{id:'1',name:'name1',number:'32423423'}]);
        setStructure({id:'1',name:'Jose',number:'23442343'});
    },[])
  return (
        <TableSelect structure={{id:'1',name:'Jose',number:'23442343'}} data={[{id:'1',name:'name1',number:'32423423'},{id:'1',name:'name1',number:'32423423'},{id:'1',name:'name1',number:'32423423'}]} />
  );
};

export default ModalCalendar;
