
import React from 'react';
import LoginLogo from "../login/LoginLogo";
import "./styles/styles.css";
import { NavLink } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Logout from './Logout';
const Header = () => {
    const navigate = useNavigate();
    const handleNavLink = () => navigate("/login");
    return (
        <header className="row message-header">
            <div className="col h-60">
            <div className="row h-100">
                <div className="col-3 d-flex justify-content-center align-items-center bg bg-dark"><LoginLogo className='login-logo bg bg-dark' /></div>
                <div className="col-8 d-flex justify-content-end align-items-center bg bg-dark"></div>
                    <Logout handleNavLink = {handleNavLink} />
                </div>
            </div>
        </header>
    )
}
export default Header;