import { useState } from "react";
import { connectionData, endpoint } from "../../connection/connection";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
    const [ email , setEmail ] = useState('');
    const [ password , setPassword ] = useState('');
    const navigate = useNavigate();
    const handleForm =async (e) => {
        e.preventDefault();
        const data = {
            email: email,
            password: password
        }
        navigate('/message');
        /*
        const result = await connectionData(endpoint,'users/login','POST',data);
        localStorage.setItem('user', JSON.stringify(result));
        navigate('/message');
        console.log(result);
        */
    }
    return (
        <>
            <form className="form" onSubmit={(e) => handleForm(e)}>
                <div className="row m-3"><input onChange={(e) => setEmail(e.target.value)} className="col rounded" placeholder="Email" type="email"/></div>
                <div className="row m-3"><input onChange={(e) => setPassword(e.target.value)} className="col rounded" placeholder="Password" type="password"/></div>
                <div className="row m-3"><input className="col btn btn-primary text-white rounded" type="submit" /></div>
            </form>
        </>
    );
}
export default LoginForm;