import { useState } from "react";
import LoginCard from "./LoginCard";
import './styles/styles.css';

const Login = () => {
    const [ flagLogInRegister , setFlagLoginRegister ] = useState(false);
    return (
        <div className="row d-flex Login border border-dark vh-100 img-bg">
            <div className="col login-bg d-flex justify-content-center align-items-center">
                <LoginCard setFlagLoginRegister={setFlagLoginRegister} />
            </div>
        </div>
    )
}
export default Login;