import React from 'react';
import logoutImg from './styles/LOGOUT.png';
import './styles/styles.css';

const Logout = (props) => {
  return (
    <div className='col-1 d-flex bg-dark justify-content-center align-items-center'>
      <div className='logout-container d-flex align-items-center'>
        <img onClick={() => props.handleNavLink()} className='logout ml-2' src={logoutImg} alt="check" />
        <p className='text-sm ml-2 mb-0'>Salir</p>
      </div>
    </div>
  );
};

export default Logout;

