import { useEffect, useState } from "react";
import Table from "./Table";
import TableSelect from "./TableSelectNumber";
import { connectionData, endpoint } from "../connection/connection";
import FormTable from "./FormTable";
import './styles/styles.css';

const CardData = (props) => {
    const [ dataTable , setDataTable ] = useState([]);
    const [ filteredDataTable , setFilteredDataTable ] = useState([]);
    const [ createForm , setCreateForm ] = useState(false);
    useEffect(()=>{
        const fetchData = async () => {
            console.log(props);
            if (props.title) {
                const newDataTable = await connectionData(endpoint, props.title, 'GET');
                setDataTable(newDataTable);
                setFilteredDataTable(newDataTable);
            }
        }
    },[props.title])
    const handleButtonForm = () => setCreateForm(!createForm);
    return (
        <div className="col-9 message-message h-100 bg bg-dark ">
            <div className="card bg-dark text-white w-100 h-75">
                <div className="card-header">{props.title}</div>
                <div className="card-body" style={{ maxHeight: '800%', overflowY: 'auto' }}>
                    <button onClick={handleButtonForm} className="m-3 btn button-add btn-outline-secondary">+{' '+props.title}</button>
                    
                   { !createForm? 
                   (props.title==='callendar')?
                        <TableSelect handleCloseModal={props.handleCloseModal} data={props.data} structure={props.dataStructure} title={props.title}/>
                            :
                        <Table title={props.title} structure={props.dataStructure} />
                        : <FormTable structure={ props.dataStructure } title ={props.title}/>}
                </div>
            </div>
        </div>
    );
}
export default CardData;