import IgImage from './styles/IN.png';
import LnImage from './styles/LN.png';
import './styles/styles.css';
const Redes = () => {
    return (
        <div className="col footer-col">
            <div className="row redes">
                <div className="col d-flex justify-content-center"><h5 className='redes'>Redes</h5></div>
            </div>
            <div className="row redes">
                <div className="col text-center"></div>
            </div>
            <div className="row redes">
                <div className="col d-flex justify-content-center mt-5"><img className='login-logo' src={IgImage} alt="check" /></div>
            </div>
            <div className="row redes">
                <div className="col d-flex justify-content-center mt-2"><img className='login-logo' src={LnImage} alt="check" /></div>
            </div>

        </div>
    )
}
export default Redes;