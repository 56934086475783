import { useState } from "react";
import SelectMessage from "./SelectMessage";
import { connectionData, endpoint } from "../connection/connection";
import { Modal, Button, ModalBody } from 'react-bootstrap';

const TableSelect = (props) => {
    const [ enviar , setEnviar ] = useState([]);
    const [ message , setMessage ] = useState('');
    const [ numbers , setNumbers ] = useState([]);
    const [ isChecked , setIsChecked ] = useState(false);
    const [ objectLista , setObjectLista ] = useState({});
    const adaptDate = (start) => {
        const inputDate = new Date(start);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const hours = String(inputDate.getHours()).padStart(2, '0');
        const minutes = String(inputDate.getMinutes()).padStart(2, '0');
        const seconds = String(inputDate.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    const createObjectLista = async (start,numbers,message) => {
        const newObjectLista = {
            date:adaptDate(start),
            contact:numbers,
            message:message
        }
        setObjectLista(newObjectLista);
        connectionData(endpoint,'listas','POST',newObjectLista).then(
           () => {
            console.log('creado')
            props.handleCloseModal(false)
            }
        );
    }
    const handleNumbers = (data) => {
        setNumbers((prevNumbers) => {
          const newNumbers = [...prevNumbers, data.phone];
          console.log(newNumbers);
          return newNumbers;
        });
    };
    return (
        <>  
        <div>
                <ModalBody> <SelectMessage setMessage={setMessage} />
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className="text-muted">Select</th>
                                {Object.keys(props.structure).map((element) => (
                            <th key={element} className="text-muted">{element}</th>
                            ))}   
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((data, index) => (
                        <tr key={index}>
                            <td><input onChange={(e) => e.target.checked && handleNumbers(data)} type="checkbox" /></td>
                            {Object.values(data).map((element, index) => (
                            <td key={index} className="text-muted" >{element}</td>
                            ))}
                        </tr>
                        ))}
                    </tbody>
                </table>
            {message && <button className="btn btn-primary btn-outline" onClick={() => createObjectLista(props.newDate.start,numbers,message)}>Agendar</button>}</ModalBody>

           
        </div>
            
        </>
    );
};

export default TableSelect;
