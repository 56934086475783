import React, { useEffect, useState } from "react";
import { connectionData, endpoint } from "../connection/connection";

const SelectMessage = (props) => {
  const [messages, setMessages] = useState([]);
  
  const handleMessage = (event) => {
    const selectedMessage = event.target.value;
    console.log(selectedMessage);
    props.setMessage(selectedMessage); // Si no se encuentra el mensaje, establece una cadena vacía
  }

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messagesData = await connectionData(endpoint, 'messages', 'GET');
        setMessages(messagesData);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div>
      <select onChange={handleMessage}>
        <option value="">Selecciona un mensaje</option>
        {messages.map((message, index) => (
          <option key={index} value={message.text}>
            {message.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectMessage;
