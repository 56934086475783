const FormTable = (props) => {
    const handleForm = (e) => {
        e.preventDefault();
    }
    return (
        <div className="card bg bg-dark">
            <div className="card-header bg bg-dark"><h4>{props.title}</h4></div>
            <div className="card-body  bg bg-dark">            
                <form onSubmit={handleForm}>
                    <div className="row">
                        {Object.keys(props.structure).map((inputName) =>(
                            (inputName !== 'id' &&
                            <div className="col">
                                <label className="text-light">{inputName}</label>
                                <input className="form-control" placeholder={inputName} />
                            </div>)
                        ))}
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <button className="btn btn-danger">Del</button>
                        </div>
                        <div className="col">
                            <button className="btn btn-success">Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default FormTable;