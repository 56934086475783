import { useState } from "react";
import CardData from "../../components/CardData";
import Menu from "../menu/Menu";
import './styles/styles.css';
import CalendarApp from "../callendar/Calendar";
import Footer from "../footer/Footer";
import Header from "../Header/Header";
const Messages = () => {
    const [ title , setTitle ] = useState('contacts');
    const [ data , setData ] = useState([]);
    const [ dataStructure , setDataStructure ] = useState({id:'1',name:'Jose',number:'23442343'});
    const [ flag , setFlag ] = useState(false);
    return (
        <main className="row vh-100 bg bg-dark">
            <div className="col vh-100 bg bg-dark">
                    <Header />
                <section className="row w-100 h-75 bg bg-dark">
                    <Menu setTitle={setTitle} setData={setData} setDataStructure={setDataStructure} flag ={flag} setFlag={setFlag}/>
                        {title=='callendar'?(
                    <CalendarApp title={title} data={data} dataStructure={dataStructure}  />
                            ):(
                    <CardData title={title} data={data} dataStructure={dataStructure} />
                        )}    
                </section>   
                    <Footer />      
            </div>     
        </main>
    )
}
export default Messages;