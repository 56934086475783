import React, { useEffect, useState } from "react";
import { connectionData, endpoint } from "../connection/connection";
import TBody from "./TBody";
import THead from "./THead";
import InputFilter from "./InputFilter";
const Table = (props) => {
    const [ dataTable , setDataTable ] = useState([]);
    const [ flagDelete , setFlagDelete ] = useState(false);
    const [ inputFilterData , setInputFilterData ] = useState([]);
    useEffect(() => {
        const dataFetch = async () => {
            console.log(props);
            if (props.title) {
                const newDataTable = await connectionData(endpoint, props.title, 'GET');
                setDataTable([{id:'1',subject:'text 1',text:'texto 1 a enviar'},{id:'2',subject:'text 2',text:'texto 2 a enviar'},{id:'3',subject:'text 3',text:'texto 3 a enviar'},{id:'4',subject:'text 4',text:'texto 4 a enviar'}]);
                console.log(props.title);
                switch (props.title) {
                    case 'contacts':
                        setDataTable([{id:'1',name:'contact 1',contact:'246544654654'},{id:'1',name:'contact 1',contact:'246544654654'},{id:'1',name:'contact 1',contact:'246544654654'},{id:'1',name:'contact 1',contact:'246544654654'}]);
                        break;
                    case 'messages':
                        setDataTable([{id:'1',subject:'text 1',text:'Message 1 a enviar'},{id:'2',subject:'text 2',text:'texto 2 a enviar'},{id:'3',subject:'text 3',text:'texto 3 a enviar'},{id:'4',subject:'text 4',text:'texto 4 a enviar'}]);
                        break;
                    case 'sends':
                        setDataTable([{id:'1',date:'Jose',numbers:'23442343',text:'agencia'},{id:'1',date:'Jose',numbers:'23442343',text:'agencia'},{id:'1',date:'Jose',numbers:'23442343',text:'agencia'},{id:'1',date:'Jose',numbers:'23442343',text:'agencia'}]);
                        break;
                    case 'users':
                        setDataTable([{id:'1',user:'user1',password:'13123123'},{id:'1',user:'user1',password:'13123123'},{id:'1',user:'user1',password:'13123123'},{id:'1',user:'user1',password:'13123123'},{id:'1',user:'user1',password:'13123123'},{id:'1',user:'user1',password:'13123123'},{id:'1',user:'user1',password:'13123123'}]);
                        break;
                    default:
                        break;
                }
                setFlagDelete(false);
            }    
        }
            dataFetch();
            console.log(flagDelete)
            console.log("UseEffect");
    }, [props, flagDelete ]);
    useEffect(() => {
        const handleFilter = () => {
            const filteredData = dataTable.filter((data) => (
                Object.values(data).some((value) =>
                    String(value).toLowerCase().includes(inputFilterData)
                )
            ));
            setDataTable(filteredData);
        }
        handleFilter();
    },[inputFilterData])
    const handleDeleteElement = async (id) => {
        console.log(props)
        const response = await connectionData(endpoint, props.title + "/" + id, "DELETE");
        response.affectedRows && setFlagDelete(true);
    }
    return (
        <>
            <InputFilter setInputFilterData={setInputFilterData} />
            <div className="table-responsive">
                <table className="table table-dark table-striped" >
                    <THead structure={props.structure} />
                    <TBody data={dataTable} handleDeleteElement={handleDeleteElement} />
                </table>
            </div>
        </>
    );
};

export default Table;
