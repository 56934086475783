import { useState } from "react";
import { connectionData, endpoint } from "../../connection/connection";

const LoginFormRegister = (props) => {
    const [ name , setName ] = useState('');
    const [ email , setEmail ] = useState('');
    const [ password , setPassword ] = useState('');
    const handleFormRegister = async (e) => {
        e.preventDefault();
        const data = {
            name:name,
            email:email,
            password:password
        }
        const result = await connectionData(endpoint,'users','POST',data);
            result.affectedRows && props.setFlagLoginRegister(!props.flagLogInRegister);
        console.log(result);
    }
    return(
        <>
            <form onSubmit={(e) => handleFormRegister(e)} className="form">
                <div className="row m-3"><input onChange={(e) => setName(e.target.value)} className="col rounded" placeholder="Name" type="text"/></div>
                <div className="row m-3"><input onChange={(e) => setEmail(e.target.value)} className="col rounded" placeholder="User" type="text"/></div>
                <div className="row m-3"><input onChange={(e) => setPassword(e.target.value)} className="col rounded" placeholder="Password" type="password"/></div>
                <div className="row m-3"><input className="col btn btn-primary text-white rounded" type="submit" /></div>
            </form>
        </>
    )
}
export default LoginFormRegister;