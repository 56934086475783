import React from 'react';
import checkImage from './styles/LOGO-WHITE.png';
import "./styles/styles.css";

const LoginLogo = (props) => {
    return (
        <img className='login-logo' src={checkImage} alt="check" />
    );
};

export default LoginLogo;