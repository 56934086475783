import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles/styles.css'; // Asegúrate de importar tu archivo de estilos

library.add(far, fas);

const MenuItem = (props) => {
    return (
        <>
            <div onClick={props.onClick} className="col item-col d-flex align-items-center text-white">
                <FontAwesomeIcon className='ml-2' icon={props.icon} />
                <span className="ml-2 smal-font">{props.nameItem}</span>
            </div>
        </>
    )
}

export default MenuItem ;
