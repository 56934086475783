import Contacto from "./Contacto";
import Enlaces from "./Enlaces";
import Redes from "./Redes";
import "./styles/styles.css";
const Footer = () => {
    return (
        <footer className="row footer">
            <Contacto />
            <Enlaces />
            <Redes />         
        </footer>
    );
}
export default Footer;
