const THead = (props) => {
    return (
        <>
            <thead>
                    <tr>
                        {Object.keys(props.structure).map((element) => (
                            (element!=="id" && <th key={element}>{element}</th>)
                        ))}
                        <th className="text-muted">Actions</th>
                    </tr>
                </thead>
        </>
    )
}
export default THead;