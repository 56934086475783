import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './views/login/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Messages from './views/message/Messages';
function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/login" element={<Login className='h-100'/>}/>
              <Route path="/" element={<Login className='h-100'/>}/>
              <Route path="/message" element={<Messages/>} />
          </Routes>
      </BrowserRouter>  
  );
}
export default App;
