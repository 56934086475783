import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import './styles/styles.css';
import ModalCalendar from './ModalCalendar.js';
import BasicModal from './BasicModal.js';
import CardData from '../../components/CardData.js';
import { connectionData, endpoint } from '../../connection/connection.js';
import TableSelect from '../../components/TableSelectNumber.js';
const localizer = momentLocalizer(moment);

const CalendarApp = (props) => {
    const [ listas , setListas ] = useState([]);
    const [ openModal , setOpenModal ] = useState(false);
    const [events, setEvents] = useState([
        {
            title: 'Text To Send',
            start: new Date(),
            end: new Date(),
        },
    ]);
    useEffect(() => {
        const fetchData = async () => {
            const fetchedListas = await connectionData(endpoint, 'listas', 'GET');
            setListas(fetchedListas);
            setEvents((prevEvents) => [
                ...prevEvents,
                ...fetchedListas.map((element) => ({
                    title: "events",
                    start: new Date(element.date),
                    end: new Date(element.date),
                })),
            ]);
        };
     //   fetchData();
    }, []);
    const handleCloseModal = () => setOpenModal(false);

    const handleSelecting = ({ start, end }) => {
          console.log('Seleccionando intervalo de tiempo:', start, end);
      };
    const handleSelectSlot = ({ start, end }) => {
        const newEvent = {
            title: 'Text To Send',
            start: new Date(start),
            end: new Date(end)
        }
        setEvents( (events) => [...events,newEvent] );
        setOpenModal(true);
    };
  

    return ( 
        <div className="col-9 message-message h-100 bg bg-dark ">  { openModal ? 
                    <TableSelect handleCloseModal={handleCloseModal} newDate ={events[events.length-1]} data={props.data} structure={props.dataStructure}/> 
                        :
                    <Calendar
                            localizer={localizer}
                          //  events={events}
                            startAccessor="start"
                            endAccessor="end"
                            views={['week', 'day','month']}
                            defaultView='day' 
                            onSelectEvent={(event) => console.log(event)}
                            selectable= {true}
                            onSelecting={handleSelecting}
                            onSelectSlot={handleSelectSlot}
                            className='calendar-component'
                    />
        } </div>    
    );
};

export default CalendarApp;
