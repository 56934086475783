import checkImage from './styles/LOGO-WHITE.png';
import './styles/styles.css';
const Contacto = () => {
    return (
        <div className="col footer-col">
            <div className="row contacto">
                <div className="col d-flex justify-content-center"><h5 className='contacto'>Contacto</h5></div>
            </div>
            <div className="row contacto">
                <div className="col d-flex justify-content-center mt-2">
                    <img className='login-logo' src={checkImage} alt="check" width="100" height="100" />
                </div>
            </div>
            <div className="row contacto">
                <div className="col d-flex justify-content-center"><p>Gestion de comunicacion</p></div>
            </div>
            <div className="row contacto">
                <div className="col d-flex justify-content-center"><p>Sede Argentina</p></div>
            </div>
            <div className="row contacto">
                <div className="col d-flex justify-content-center"><p>Sede Alemania</p></div>
            </div>
            <div className="row contacto">
                <div className="col d-flex justify-content-center"><p>info@tcoin.com</p></div>
            </div>
        </div>
    )
}
export default Contacto;