import './styles/styles.css';
const Enlaces = () => {
    return (
        <div className="col footer-col">
            <div className="row enlaces">
                <div className="col d-flex justify-content-center"><h5 className='enlaces'>Enlaces</h5></div>
            </div>
            <div className="row enlaces">
                <div className="col"></div>
            </div>
            <div className="row enlaces">
                <div className="col d-flex justify-content-center mt-5"><p>Programa de Afiliados</p></div>
            </div>
            <div className="row enlaces">
                <div className="col d-flex justify-content-center"><p>Referidos</p></div>
            </div>
            <div className="row enlaces">
                <div className="col d-flex justify-content-center"><p>Asociate</p></div>
            </div>
        </div>
    )
}
export default Enlaces;